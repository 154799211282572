import React from "react"
import Sidebar from "../../components/holdersPage/sidebar"
import Collapsible from "react-collapsible"
import styled from "@emotion/styled"
import useQuestions from "../../hooks/use-questions"
import { Helmet } from "react-helmet"

const AnswerWrapper = styled.div`
  padding: 0.5rem 1rem 0 1rem;
  background-color: #eceff2;
  font-size: 0.9rem;
  color: ${props => props.theme.colors.secondary};
  p {
    padding-bottom: 1rem;
  }
`

const QuestionWrapper = styled.div`
  background-color: #d7dde4;
  padding: 0.5rem 3rem 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
`

const QaWrapper = styled.div`
  padding-bottom: 1rem;
`

const About = props => {
  const data = useQuestions()
  const normalizedData = data.map(el => {
    return { q: el.question.questions, a: el.question.answer }
  })


  const listOfQuestions = normalizedData.map((question, indx) => (
    <QaWrapper key={indx}>
      <Collapsible trigger={<QuestionWrapper>{question.q}</QuestionWrapper>}>
        <AnswerWrapper>
          <div dangerouslySetInnerHTML={{ __html: question.a }} />
        </AnswerWrapper>
      </Collapsible>
    </QaWrapper>
  ))

  return (
    <Sidebar>
      <Helmet>
        <title>Частые вопросы</title>
        <meta
          name="description"
          content={`Частые вопросы прав дольщиков, Новость Фонда Защиты Прав Граждан участников строительства в ПК`}
        />
      </Helmet>
      <h1>Частые вопросы</h1>
      <div>{listOfQuestions}</div>
    </Sidebar>
  )
}

export default About

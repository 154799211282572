import { graphql, useStaticQuery } from "gatsby"

const useQuestions = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        questions(last: 20) {
          nodes {
            question {
              answer
              questions
            }
          }
        }
      }
    }
  `)

  return data.wordPress.questions.nodes
}

export default useQuestions





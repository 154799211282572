import React from "react"
import Sidebar from "../layout/sidebar"

const NavigationSidebar = ({ children }) => {
  return (
    <Sidebar
      links={[
        { name: "ЧАСТЫЕ ВОПРОСЫ", slug: "/holders/" },
        {
          name: "АЛГОРИТМ ВОССТАНОВЛЕНИЯ ПРАВ ДОЛЬЩИКОВ",
          slug: "/holders/algorithm/",
        },
      ]}
    >
      {children}
    </Sidebar>
  )
}

export default NavigationSidebar
